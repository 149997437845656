body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

#root {
    min-height: calc(100vh - 323px);
    margin: 0;
    padding: 0;
    /* background-color: #FFCC00; */
}

.updateProfile {
    font-size: 30px;
}


/* Show Page */

#show-image {
    max-height: 40vh;
}

mark {
    padding: 0;
    background-color: rgba(255, 255, 0, 0.548);
}

.description {
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 70%; 
    top: 25%;
    left:15%;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 0 50px 0px black;
    
}

.back-button {
    position: absolute;
}

.botanical-name-show {
    font-size: 1.5rem;
}

/* Homepage */


.title {
    width: 40%
}
.banner {
    width: 70%;
}

/* Search Page */

.multiselect-list {
    list-style: none;
    max-width: 500px;
}
.multiselect-list li{
    display: inline;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-size: 92%;
    background-color: rgb(235, 235, 235);
    margin-right: 4px;
    color: rgb(94, 94, 94);
    font-weight: normal;
}

input.multiselect {
   width: 70.9%
}

.search-form {
    min-height: 50vh;
}

#list-example > a#list {
    border-color: rgb(66, 116, 255);
}

#list-example > a#list:hover {
    background-color: rgb(235, 235, 235);
}

.list-group-item {
    margin: 3px;
    border: none;
    border-left: 4px solid rgb(66, 116, 255);
    border-radius: 0px;
}

#list-example > a {
    background-color: white;
    border-color:inherit;
    color: black;
    margin: 3px;
}

/* footer */

#footer {
    margin-top: 16px;
}

footer {
    background-color: #212529;
    text-align: center;
    color: white
}



.social-icons {
    font-size: 35px;
}

a.footer-a {
    color: white;
}

/* Contact Us Page */

.font-size-lg {
    font-size: 21px;
}


/* suggestion box on autocomplete */

.react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    z-index:100;
    width: 72%;
    box-shadow: 0px 2px 5px rgb(143, 143, 143);
    /* border: 1px solid #aaa; */
    background-color: #fff;
    /* font-family: "Open Sans", sans-serif; */
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
      font-weight: 400;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: rgb(230, 230, 230);
    font-weight: 500;
  }

  .react-autosuggest__container {
      display: inline;
  }
  

.fixed {
    position: sticky;
}

.form-control-sm {
    border: 1px solid #ced4da;
    width: 90%
    /* border-radius: 10px; */
}

  .home {
      height: 100%;
      position: absolute;
      z-index:-1;
  }

/* Filter */
.filter-container {
    position: relative;
    display: inline-block;
  }
  .filter-button-component {
    border: 1px solid #2a8ce9;
    color: #FFFFFF;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    outline: 0 !important;
    background: linear-gradient(#2d84e7, #26afee);
  }
    
  .filter-button-component:hover {
      -webkit-filter: brightness(120%);
      filter: brightness(120%);
    }
    
    .filter-button-component:active {
       background: linear-gradient(#115da3, #2265a3);
    }
  
  
  .filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 360px;
    z-index: 2;
    max-height: 300px;
    background-color: rgb(242, 242, 242);
    border-radius: 0px 0px 0px 15px;
    overflow-y: scroll;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }
  
  .filter-checkboxes ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .filter-checkboxes li {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 16px;
  }
  
  .filter-checkboxes li:hover {
    background-color: rgba(0, 0, 0, 0.14);

  }
  
  .filter-apply-button {
    width: 100%;
    border: 1px solid #2a8ce9;
    color: #FFFFFF;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 0px 0px 0px 15px;
    outline: 0 !important;
    background: linear-gradient(#2d84e7, #26afee);
  }

  .filter-apply-button:hover {
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
  }
  
  .filter-checkboxes label{
    margin: 0px 20px 8px 8px;
    transform: translateY(-0.05em);
  }

  .filter-checkboxes p{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .filter-checks{
      cursor: pointer;
  }
  
  /* Navbar logo */
  .navbar-brand img {
    filter: brightness(200%);
    
  }
.nav-tabs .nav-item{
  font-size: 15px;
}
  


  